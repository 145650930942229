// import { SET_CATEGORY, SET_SUPPLIER } from "../helper";
// import { FILTER_CATALOG } from '../helper';

const middleware = action => next => {

  // if (action.type === SET_CATEGORY) {
    // console.log(action);
  // }

  // if (action.type === SET_SUPPLIER) {
  //   console.log(action);
  // }

  next(action);
};

export default middleware;