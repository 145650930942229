export const helperStore = {
    helper: {
      category: false,
      supplier: false,
    }
  };

export const SET_CATEGORY = "SET_CATEGORY";
export const SET_SUPPLIER = "SET_SUPPLIER";

const helperReducer = (state = helperStore, action) => {
    switch (action.type) {
      case SET_CATEGORY:
        return {
          helper: {
            ...state.helper,
            category: action.payload,
            supplier: false,
          }
        };
      case SET_SUPPLIER:
          return {
            helper: {
              ...state.helper,
              supplier: action.payload,
              category: false,
            }
          };
      default:
        return state;
    }
};

export const SetStoredCategory = category => {
  return {
    type: SET_CATEGORY,
    payload: category
  };
};

export const SetStoredSupplier = supplier => {
  return {
    type: SET_SUPPLIER,
    payload: supplier
  };
};

export default helperReducer;