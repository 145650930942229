import React, { lazy, Suspense } from 'react';
import ScrollToTop from './Utils/ScrollToTop';
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";
import { Switch, Route, BrowserRouter, useLocation } from 'react-router-dom';
import './Router.css';
import Connect from '../store/config/connect';

const Home = lazy(() => import('../pages/Home/Home'));
const ResearchDashboard = lazy(() => import('../pages/ResearchDashboard/ResearchDashboard'));

const AnimationRoute = () => {
  let location = useLocation();

  return (
      <TransitionGroup className="transition-group">
          <CSSTransition
              key={location.key}
              classNames="fade"
              timeout={500}
          >
            <section className="route-section">
              <Suspense fallback={<div></div>}>
                <Switch location={location}>
                  <Route path="/" exact component={Home} />
                  <Route path="/pesquisa" exact component={ResearchDashboard} />
                  <Route path="/pesquisa/:category/:categoryId/:subCategory/:subCategoryId" exact component={ResearchDashboard} />
                </Switch>
              </Suspense>
            </section>
          </CSSTransition>
        </TransitionGroup>
    );
}

function Router() {

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <AnimationRoute />
      </Switch>
    </BrowserRouter>
  );
}


export default Connect()(Router);