import React from 'react';
import Router from '../router/Router';

const App = props => {
    return (
        <Router/>
    );
}

export default App;
